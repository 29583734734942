import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import StudentList from './components/StudentList';
import StudentDetail from'./components/StudentDetail';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import PointsDisplay from  './components/PointsDisplay';
import StudentSearch from './components/StudentSearch';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<StudentSearch/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/students" element={<ProtectedRoute><StudentList /></ProtectedRoute>}/>
        <Route path="/students/:id" element = {<ProtectedRoute><StudentDetail/></ProtectedRoute>}/>
        <Route path = "/points/:studentId" element = {<PointsDisplay/>}/>
      </Routes>
    </Router>
  );
}

export default App;
