import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Form, Button, Table, Container, Row, Col, Image} from 'react-bootstrap'

const StudentSearch = ({ onStudentSelect }) => {
  const [name, setName] = useState('');
  const [students, setStudents] = useState([]);

  const searchStudents = async () => {
    try {
      const response = await axios.get(`https://api.bellinghamcodingclub.com/point_tracker/api/students-list/?first_name=${name}`);
      setStudents(response.data);
    } catch (error) {
      console.error('Error fetching students', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault ();
    searchStudents ();
  };

  return (
    <Container>
      <Row className="justify-content-center my-5">
        <Col md = {6}>
          <Image src="/images/Bellingham+and+Skagit+Logo+Website.png" alt="It's the BCRC Logo!" fluid/>
        </Col>
      </Row>
      <Row>
      <Col>
          <h1 className='text-center mb-3'>Point Tracker</h1>
        </Col>
      </Row>
      <Row className="justify-content-center my-5">
        <Col md={6}>
          <h3 className = "text-center mb-4">Search for Your Name</h3>
          <Form onSubmit={(e) => handleSubmit(e)} className = "mb-4">
            <Form.Group>
              <Row className="align-items-center">
                <Col xs={9}>
                  <Form.Control 
                    type = "text"
                    placeholder="Enter first name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onSubmit={searchStudents}
                  />
                </Col>
                <Col xs={3}>
                  <Button variant="primary" onClick={searchStudents}>
                    Search
                  </Button>
                </Col>
              </Row>
            </Form.Group>

          </Form>

          {students.length > 0 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key = {student.id}>
                    <td colSpan={3}>
                      <Link to={`/points/${student.id}`} style={{ display: 'block', color: 'inherit', textDecoration: 'none' }}>
                          <Row>
                            <Col>{student.first_name}</Col>
                            <Col>{student.last_name}</Col>
                          </Row>
                        </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default StudentSearch;
