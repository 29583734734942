import React from 'react';
import {Navbar, Nav, Button, Image} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate ();

    const handleSignOut = () => {
        localStorage.removeItem('token');
        navigate('/login');
    }

    return (
        <Navbar expand="lg">
            <Navbar.Brand href='/students'> 
                <Image src = "/images/BCRCRobotHead.png"
                width = "100" height = "50" rounded />
                <span className="ml-auto">Student Points Tracker</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
                <Nav>
                    <Button variant = "secondary" onClick={handleSignOut}>
                        Sign Out
                    </Button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header;