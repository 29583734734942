import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Form, Container, Row, Col, InputGroup, Button, Modal, Stack, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from './Header'

function StudentList() {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [first_name, setFirstName] = useState ('');
  const [last_name, setLastName] = useState ('');

  const handleShow = () => setShowModal(true);
  const handleHide = () => setShowModal(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    const data = {
      first_name:first_name,
      last_name:last_name,
      point_records:[]
    };
    
    if (form.checkValidity() === false){
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated (true);

    if (form.checkValidity()){
      console.log ("First Name:", first_name, " Last Name:", last_name);
      axios.post (`https://api.bellinghamcodingclub.com/point_tracker/api/students/add-student`,
      data, {headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
      }})
      .then (response => {
        setStudents([...students, response.data]);
        setFirstName('');
        setLastName('');
        handleHide();
      })
    }
  }

  useEffect(() => {
    axios.get('https://api.bellinghamcodingclub.com/point_tracker/api/students', {headers: {Authorization: `Token ${localStorage.getItem('token')}`}})
      .then(response => {
        setStudents(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching students!", error);
      });
  }, []);

  // Filter the students based on search term
  const filteredStudents = students.filter(student =>
    student.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    student.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Header/>
      <Container >
      <Row className="justify-content-center my-4">
        <Col md={6}>
          <h1 className='text-center mb-5'>Student List</h1>
        </Col>
      </Row>

      <Row className='justify-content-center mb-4'>
        
          {/* Search Input */}
          <Stack direction='horizontal' gap={3}>
            <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Search by name"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <Button onClick={handleShow}>+ Add Student</Button>
          </Stack>
      </Row>

      <Row className='justify-content-center'>
        <Col >
        <div className="scrollable-table" style={{ maxHeight: '600px', overflowY: 'scroll' }}>
          {/* Bootstrap Table for Student List */}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((student, index) => (
                <tr key={student.id}>
                <td colSpan={3}>
                  <Link to={`/students/${student.id}`} style={{ display: 'block', color: 'inherit', textDecoration: 'none' }}>
                    <Row>
                        <Col>{student.first_name}</Col>
                        <Col>{student.last_name}</Col>
                        <Col>{student.point_records.reduce((total, record) => total + record.points, 0)}</Col>
                    </Row>
                    </Link>
                </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
        </Col>
      </Row>
      </Container>

      <Modal show={showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton className='ModalForm'>
                    <Modal.Title>Add new Student</Modal.Title>
                </Modal.Header>
                <Modal.Body className='ModalForm'>
                    {/* Form inside the modal */}
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        {/* First Name Field */}
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Student First Name"
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid first name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Last Name Field */}
                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Student Last Name"
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid last name.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='ModalForm'>
                    <Button variant="secondary" onClick={handleHide}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
    </div>
  );
}

export default StudentList;
