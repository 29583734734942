import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Header from './Header'
import {Table, Form, Row, Col, Button, Modal} from 'react-bootstrap'

function StudentDetail() {
  const { id } = useParams();  // Get the student ID from the URL
  const [student, setStudent] = useState(null);
  const [pointRecords, setPointRecords] = useState([]);
  const [newPoints, setNewPoints] = useState(0);
  const [reason, setReason] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleHide = () => setShowModal(false);

  // Fetch student details and point records
  useEffect(() => {
    axios.get(`https://api.bellinghamcodingclub.com/point_tracker/api/students/${id}`, {headers: {Authorization: `Token ${localStorage.getItem('token')}`}})
      .then(response => setStudent(response.data))
      .catch(error => console.error("Error fetching student:", error));

    // Fetch point records
    axios.get(`https://api.bellinghamcodingclub.com/point_tracker/api/students/${id}/point-records`, {headers: {Authorization: `Token ${localStorage.getItem('token')}`}})
      .then(response => setPointRecords(response.data))
      .catch(error => console.error("Error fetching point records:", error));
  }, [id]);

  // Handle adding new points
  const handleAddPoints = (event) => {
    const form = event.currentTarget;
    const data = {
      points: newPoints,
      reason: reason,
      date: new Date().toISOString()  // Use the current date
    };

    if (form.checkValidity() === false){
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated (true);

    axios.post(`https://api.bellinghamcodingclub.com/point_tracker/api/students/${id}/add_points`, data, {
        headers:{
            'Authorization': `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
        }
    })
      .then(response => {
        setPointRecords([...pointRecords, response.data]);  // Append the new point record to the list
        setNewPoints(0);
        setReason("");
        handleHide();
        setValidated(false);
      })
      .catch(error => console.error("Error adding point record:", error));
  };

  if (!student) return <div>Loading...</div>;

  return (
    <div>
      <Header/>

      <Row className='justify-content-center my-5'>
        <Col><h1 className='text-center'>{student.first_name} {student.last_name}</h1></Col>
      </Row>
      <Row className='justify-content-center my-5'>
        <Col>
          <h3 className='text-center'>Point Records</h3>
        </Col>
      </Row>
      <Row className='justify-content-center'>
        <Col md={7}> 
        <div className='scrollable-table' style={{maxHeight:'500px', overflowY:'scroll'}}>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Points</th>
                <th>Date</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {pointRecords.map(record => (
                <tr>
                  <td>{record.points}</td>
                  <td>{new Date(record.date).toLocaleDateString()}</td>
                  <td>{record.reason}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        </Col>
      </Row>

      <Row className='justify-content-center my-5'>
        <Col className="text-center">
          <Button onClick={handleShow} md={4}>+ Add Points</Button>
        </Col>
      </Row>

      <Row className='justify-content-center my-5'>
        <Col className="text-center">
          <Link to="/students">
            <Button md={4}>Back to Student List</Button>
          </Link>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleHide} backdrop="static">
        <Modal.Header closeButton className='ModalForm'>
          <Modal.Title>Add Points to Student</Modal.Title>
        </Modal.Header>
          <Modal.Body className='ModalForm'>
            {/* Form inside the modal */}
            <Form noValidate validated={validated} onSubmit={handleAddPoints}>
              {/* Points Field */}
              <Form.Group controlId="formPoints">
                  <Form.Label>Points</Form.Label>
                  <Form.Control
                      required
                      type="number"
                      placeholder="Enter points"
                      value={newPoints}
                      onChange={(e) => setNewPoints(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                      Please enter a valid number of points.
                  </Form.Control.Feedback>
              </Form.Group>
            {/* Reason Field */}
            <Form.Group controlId="formReason">
                <Form.Label>Reason</Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Reason for the points"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                    Please enter a reason.
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
          </Modal.Body>
          <Modal.Footer className='ModalForm'>
              <Button variant="secondary" onClick={handleHide}>
                  Cancel
              </Button>
              <Button variant="primary" onClick={handleAddPoints}>
                  Submit
              </Button>
          </Modal.Footer>
        </Modal>
      </div>
  );
}

export default StudentDetail;
