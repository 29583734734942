import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {Container, Row, Col, Form, Button, Image} from 'react-bootstrap';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername (e.target.value.toLowerCase())
  };

  const handleLogin = (e) => {
    e.preventDefault();
    axios.post('https://api.bellinghamcodingclub.com/point_tracker/api/login/', { username, password })
      .then(response => {
        localStorage.setItem('token', response.data.token);  // Save token
        navigate('/students');  // Redirect to student list after login
      })
      .catch(error => {
        setError('Invalid credentials');
      });
  };

  return (
    <Container>
       <Row className="justify-content-center my-5">
        <Col md = {6}>
          <Image src="/images/Bellingham+and+Skagit+Logo+Website.png" alt="It's the BCRC Logo!" fluid/>
        </Col>
      </Row>
      <Row className="justify-content-center my-5">
        <Col md = {6}>
          <h1 className='text-center'>Points Tracker Login</h1>
        </Col>
      </Row>
      <Row className="justify-content-center my-5">
        <Col md = {6}>
          {error && <p>{error}</p>}
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb3" controlId="formUsername">
              <Form.Label>Username:</Form.Label>
              <Form.Control type="text" placeholder='Enter Username' onChange={handleUsernameChange}/>
            </Form.Group>

            <Form.Group className="mb3" controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder='password' onChange={e => setPassword(e.target.value)}/>
            </Form.Group>
            <Button variant='primary' type="submit">Login</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
