import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';  // For responsive confetti size
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Row, Col, Button} from 'react-bootstrap';

const PointsDisplay = () => {
  const { studentId } = useParams()
  const [student, setStudent] = useState(null)
  console.log ('Got studentId: ', studentId);
  const [points, setPoints] = useState(0);
  const { width, height } = useWindowSize();  // For confetti sizing
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();
  const [pointRecords, setPointRecords] = useState([]);

  console.log ("Mounted the PointsDisplay")

  useEffect(() => {
    if (!studentId) {
      console.error("No studentId found!");
      return;
    }

    axios.get(`https://api.bellinghamcodingclub.com/point_tracker/api/students/${studentId}`)
    .then(response => setStudent(response.data))
    .catch(error => console.error("Error fetching student:", error));

    const fetchPoints = async () => {
      try {
        console.log ('Fetching points for student id ', studentId);
        const response = await axios.get(`https://api.bellinghamcodingclub.com/point_tracker/api/students/${studentId}/total-points`);
        setPoints(response.data.total_points);
        setShowConfetti(true);  // Trigger confetti
      } catch (error) {
        console.error('Error fetching student points', error);
      }
    };

    const fetchPointRecords = async () => {
      axios.get(`https://api.bellinghamcodingclub.com/point_tracker/api/students/${studentId}/point-records`)
      .then(response => setPointRecords(response.data))
      .catch(error => console.error("Error fetching point records:", error));
    }

    fetchPoints();
    fetchPointRecords ();
  }, [studentId]);

  const goBackToSearch = () => {
    navigate ('/')
  }

  if (!student) return <div>Loading...</div>;

  return (
    <div className="points-display">
      <h1>{student.first_name} {student.last_name}</h1>
      <h1>Your Total Points</h1>
      <div className="points-count">{points}</div>

      {showConfetti && (
        <>
          <Confetti width={width} height={height} recycle={true} />
        </>
      )}
      <Row className="justify-content-center my-5">
        <Col md={6}>
        <div className="scrollable-table" style={{ maxHeight: '700px', overflowY: 'scroll' }}>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Points</th>
                <th>Date</th>
                <th>Reason</th>  
              </tr>
            </thead>
            <tbody>
              {pointRecords.map(record => (
                <tr key = {record.id}>
                  <td>{record.points}</td>
                  <td>{new Date(record.date).toLocaleDateString()}</td>
                  <td>{record.reason}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        </Col>
      </Row>

      <Button onClick={goBackToSearch}>Back to Search</Button>
    </div>
  );
};

export default PointsDisplay;
